import React, { Component, useEffect } from "react";
import numeral from "numeral";
import MaterialTable from "material-table";
import { quoteView } from "../../utils/form_comp_quote";
import SmallLoader from "../common/SmallLoader";
import { isDeepEquals } from "../../utils/isDeepEquals";
import { LibertateContext } from "../../context/Context";
import NewProgress from "../../newcomponent/common/NewProgress";
import { ErrorPopUp } from "./ErrorPopUpUWPage";
import ScheduledDebitCredit from "../subcompo/ScheduledDebitCredit";
import LoadingOverlay from "react-loading-overlay";
import axios from "axios";
import { awsUrl, awsUrl2 } from "../../config";
import StatusAndComments from "../subcompo/underwriting-page/StatusAndComments";
import Comments from "../subcompo/underwriting-page/Comments";
import UpdateRates from "../subcompo/UpdateRates";
import { Link } from "react-router-dom";
import { amtrustResponse } from "../../utils/form_ques";
import toast, { Toaster } from "react-hot-toast";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Auth } from "aws-amplify";
import { MdOutlineReportGmailerrorred } from "react-icons/md";
import AddCarrier from "./AddCarrier";
import AmtrustUnderQues from "../pages/AmtrustUW";
const programMapping = require("../../utils/carrierList.json").programMapping;

let isMobile = window.innerWidth < 900;
let allCarriersData = [];

const updateData = async (data) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .post(awsUrl + "/api/underWriterData/update", data)
        .then((res) => {
          console.log("data saved");
          toast.success("Data Submitted", {
            duration: 3000,
          });
          resolve();
        })
        .catch((err) => {
          console.log("error while saving", err);
          reject();
          toast.error("Error while saaving the Data", {
            duration: 3000,
          });
        });
    } catch (error) {}
  });
};

class UnderwritingTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      processingInProgress: false,
      boundError: false,
      columnseleted: " ",
      rowDataList: [],
      columns: [
        {
          title: "",
          field: "name",
          align: "center",
          headerStyle: {
            backgroundColor: "#e2f2ff",
          },
          render: (rowData) => (
            <div className="d-flex align-items-center fontName-Size justify-content-end ">
              <div className="row" style={{ width: "100%" }}>
                <div className="col-6" style={{ textAlign: "center" }}>
                  {rowData.name === "MASTER" ? "Master States" : rowData.name}
                </div>
                <div className="col-6 row">
                  <div className="col-12 smaller-text-quote-table">Premium</div>
                  <div className="col-12 smaller-text-quote-table">Payroll</div>
                  <div className="col-12 smaller-text-quote-table">Status</div>
                  <div className="col-12 smaller-text-quote-table">
                    Comments
                  </div>
                </div>
              </div>

              <span>&emsp;</span>
              {/* {rowData.name !== "TOTAL" && false && (
                <input
                  className="chekcbox-input "
                  type="checkbox"
                  checked={rowData.checked.name}
                  onChange={(e) =>
                    this.handleRowCheck(rowData, e.target.checked)
                  }
                />
              )}
              {rowData.name === "TOTAL" && (
                <div style={{ width: "16px" }}></div>
              )} */}
            </div>
          ),
        },
      ],
      download_data: [],
      cacheStateRange: {},
      carrierStatusAndComments: {},
      selectedCarrier: "",
      stateTypeList: {},
      payrollData: {},
      emptyCarrierErrorMessage: "",
      amtrustBlindButton: true,
      show: false,
      amtrustBindError: false,
    };
    this.quoteView = quoteView.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.updateShowState = this.updateShowState.bind(this);
  }

  capitalizeFirstLetter(string) {
    if (!string) {
      return ""; // Handle undefined or empty string
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  roundVaues = (a, b) => {
    let divi = 100,
      fix = 2;
    if (b) {
      divi = 10000;
      fix = 4;
    }
    if (a) return (Math.round(Number(a) * divi) / divi).toFixed(fix);
    return 0;
  };

  roundOffAndThousandSeperated = (num) => {
    let val = Math.round(Number(num));
    return "$" + val.toLocaleString("en");
  };

  handleRowCheck = (rowData, value) => {
    let rowDataListCopy = JSON.parse(JSON.stringify(this.state.rowDataList));
    let index = rowData.tableData.id;
    for (let header in rowDataListCopy[index].checked) {
      rowDataListCopy[index].checked[header] = value;
    }
    this.setState({ rowDataList: rowDataListCopy });
  };

  checkForMultipleCarrierBound = (stateCarrierData) => {
    let stateCarrierBound = {};
    for (let [carrier, carrierData] of Object.entries(stateCarrierData)) {
      for (let [state, stateData] of Object.entries(carrierData)) {
        if (stateData.status === "bound") {
          if (!stateCarrierBound[state]) stateCarrierBound[state] = new Set();
          stateCarrierBound[state].add(carrier);
          if (stateCarrierBound[state].size > 1) {
            return true;
          }
        }
      }
    }
    return false;
  };

  updateDataInUser = (data) => {
    return new Promise((resolve, reject) => {
      try {
        let data_req = JSON.parse(JSON.stringify(data));

        axios
          .post(awsUrl2 + "/api/userTableData/update", {
            user_email_id: data_req.user_email_id,
            uw_status: data_req.uw_status,
          })
          .then((res) => {
            console.log("data saved");
            resolve();
          })
          .catch((err) => {
            console.log("error while saving", err);
            reject();
          });
      } catch (error) {}
    });
  };

  checkBoundStatus = (data) => {
    for (let _carr in data) {
      for (let _stat in data[_carr]) {
        if (data[_carr][_stat].status === "bound") return true;
      }
    }
    return false;
  };

  checkStatus = (data, status) => {
    for (let _carr in data) {
      for (let _stat in data[_carr]) {
        if (data[_carr][_stat].status !== status) return false;
      }
    }
    return true;
  };

  recalculateQuoteAndSaveAllInDB = async (dataBody, dataToSave) => {
    const resID = await axios.post(
      awsUrl + `/api/getRecalculateQuoteId`,
      JSON.stringify({ body: dataBody })
    );

    let fetchData = (id) => {
      new Promise((resolve, reject) => {
        axios
          .get(awsUrl + `/api/getRecalculateQuote/${id}`)
          .then(async (res) => {
            if (res.data === "processing") {
              setTimeout(() => fetchData(id), 5000);
            } else {
              if (this.checkBoundStatus(dataToSave.stateCarrierData))
                dataToSave.uw_status = "Bound";
              else if (this.checkStatus(dataToSave.stateCarrierData, "closed"))
                dataToSave.uw_status = "Closed";
              else if (
                this.checkStatus(dataToSave.stateCarrierData, "declined")
              )
                dataToSave.uw_status = "Declined";

              await updateData(dataToSave);

              if (dataToSave.uw_status) {
                await this.updateDataInUser(dataToSave);
              }

              window.location.reload();
            }
          })
          .catch((error) => {
            console.log("error", error);
            this.setState({ processingInProgress: false });
            resolve();
          });
      });
    };

    fetchData(resID.data.id);
  };

  validateDebitCredit = (stateCarrierData) => {
    for (let [carrier, carrierData] of Object.entries(stateCarrierData)) {
      for (let [state, stateData] of Object.entries(carrierData)) {
        if (stateData.debit_credit.error) {
          return true;
        }
      }
    }
    return false;
  };

  createSdrFactor = (stateCarrierData) => {
    let sdr_factor = {};
    for (let carrier in stateCarrierData) {
      if (carrier.includes("custom_carrier_")) continue;
      if (!(carrier in sdr_factor)) sdr_factor[carrier] = {};

      for (let state in stateCarrierData[carrier]) {
        let stateData = stateCarrierData[carrier][state];

        if (stateData.debit_credit.value) {
          if (stateData.debit_credit.isChecked) {
            sdr_factor[carrier][state] = -parseInt(
              stateData.debit_credit.value
            ).toString();
          } else {
            sdr_factor[carrier][state] = parseInt(stateData.debit_credit.value);
          }
        }
      }
    }

    return sdr_factor;
  };

  removeSCDIndex = () => {
    const { stateCarrierData, setStateCarrierData } = this.props;
    Object.keys(stateCarrierData).map((s, i) => {
      s.includes("custom_carrier_") && delete stateCarrierData[s].index;
    });
    setStateCarrierData(stateCarrierData);
  };

  submitHandler = async () => {
    this.setState({ processingInProgress: true });
    let { stateCarrierData } = this.props;
    this.removeSCDIndex();

    let uid = sessionStorage.getItem("user_id");
    let uuid_list = JSON.parse(sessionStorage.getItem("sortKeyList"));

    let boundError = this.checkForMultipleCarrierBound(stateCarrierData);
    let debitCreditError = this.validateDebitCredit(stateCarrierData);
    let sdrFactor = this.createSdrFactor(stateCarrierData);

    if (boundError || debitCreditError) {
      this.setState({ boundError, processingInProgress: false });
      return;
    }

    let dataToSave = {};
    dataToSave.user_email_id = uid;
    dataToSave.sortKeyList = uuid_list;
    dataToSave.stateCarrierData = { ...stateCarrierData };
    dataToSave.sdr_factor = sdrFactor;

    this.recalculateQuoteAndSaveAllInDB(
      {
        user_email_id: uid,
        uuid_carrier: uuid_list,
        sdr_factor: sdrFactor,
      },
      dataToSave
    );
  };

  onStatusSelectHandler = (status, state, carrier) => {
    let { stateCarrierData, setStateCarrierData } = { ...this.props };
    if (!stateCarrierData[carrier]) {
      stateCarrierData[carrier] = {};
    }
    if (!stateCarrierData[carrier][state]) {
      stateCarrierData[carrier][state] = {};
    }
    stateCarrierData[carrier][state].status = status;
    setStateCarrierData(stateCarrierData);
  };
  onChangeCommentHandler = (comments, state, carrier) => {
    const { stateCarrierData, setStateCarrierData } = { ...this.props };
    if (!stateCarrierData[carrier]) {
      stateCarrierData[carrier] = {};
    }
    if (!stateCarrierData[carrier][state]) {
      stateCarrierData[carrier][state] = {};
    }
    stateCarrierData[carrier][state].comments = comments;
    setStateCarrierData(stateCarrierData);
  };
  onChangeDebitCreditHandler = (data, state, carrier) => {
    const { stateCarrierData, setStateCarrierData } = { ...this.props };
    if (!stateCarrierData[carrier]) {
      stateCarrierData[carrier] = {};
    }
    if (!stateCarrierData[carrier][state]) {
      stateCarrierData[carrier][state] = {};
    }
    stateCarrierData[carrier][state].debit_credit = data;
    setStateCarrierData(stateCarrierData);
  };

  createPopData = () => {
    const dataToShow = {
      show: this.state.boundError,
      title: "Bind Error!!",
      children: (
        <>
          <br></br>
          <span style={{ fontWeight: "bold" }}>
            You can only bind with one carrier.
          </span>
        </>
      ),
      acceptBtn: "OK",
      acceptBtnCallback: async () => {
        try {
          this.setState({ boundError: false });
        } catch (error) {
          console.log("error: ", error);
        }
      },
    };
    return dataToShow;
  };

  handleCheck = (rowData, key, value) => {
    let rowDataListCopy = JSON.parse(JSON.stringify(this.state.rowDataList));
    let index = rowData.tableData.id;
    rowDataListCopy[index].checked[key] = value;
    if (!value) {
      rowDataListCopy[index].checked.name = value;
    }
    this.setState({ rowDataList: rowDataListCopy });
  };

  prepareRowDataList = (quoteTableData, programs) => {
    let peoData = this.props.peoData;
    let rowDataList = [];
    let masterRow, totalRow;
    try {
      for (let state in quoteTableData) {
        if (state.toUpperCase() === "MASTER") {
          masterRow = {
            name: state.toUpperCase(),
            ...quoteTableData[state],
          };
        } else if (state.toUpperCase() === "TOTAL") {
          totalRow = {
            name: state.toUpperCase(),
            ...quoteTableData[state],
          };
        } else {
          rowDataList.push({
            name: state.toUpperCase(),
            ...quoteTableData[state],
          });
        }
      }

      for (let row of rowDataList) {
        if (row.name !== "MASTER" && row.name !== "TOTAL") {
          for (let program of programs) {
            if (
              !row?.[program]?.premium &&
              row?.[program]?.premium !== 0 &&
              peoData?.[program]?.[row.name.toLowerCase()]
            ) {
              row[program] = { premium: "Included in Master" };
            }
          }
        }
      }

      rowDataList.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        } else if (a.name > b.name) {
          return 1;
        } else {
          return 0;
        }
      });

      if (masterRow) {
        rowDataList.unshift(masterRow);
      }
      if (totalRow) {
        rowDataList.push(totalRow);
      }
    } catch (error) {
      console.log(error);
    }

    return rowDataList;
  };

  updateColumnSelection = (selectedProgram, programs) => {
    try {
      let columns = [...this.state.columns];

      for (let column of columns) {
        if (programs.includes(column.field)) {
          if (column.field !== this.state.columnseleted) {
            if (column.field === selectedProgram) {
              column.headerStyle = {
                ...column.headerStyle,
                backgroundColor: "#b0877d",
                borderRadius: "20px",
                // boxShadow:
                //   " 6px 10px 8px #484848 , -6px 10px 8px  #484848 ,0px -10px 8px #484848",
                transition: "all ease 0.2s",
                fontSize: "1.7rem",
                // textShadow: "0.8px 0.8px",
              };
              column.cellStyle = {
                ...column.cellStyle,

                // boxShadow: "5px 10px 7px #484848 ,-5px 10px 7px #484848",
                transition: "all ease 0.2s",
                // textShadow: "0.8px 0.8px",
              };

              this.setState({ columnseleted: selectedProgram });
            } else {
              column.headerStyle = {
                ...column.headerStyle,
                boxShadow: "none",
                backgroundColor: "#2f75b5",
                fontSize: "1.5rem",
                borderRadius: "10px",
                textShadow: "0px 0px",
                transition: "all ease 0.2s",
                backgroundColor: "#2f75b5",
              };

              column.cellStyle = {
                ...column.cellStyle,
                boxShadow: "none",
                borderLeft: "1px solid white",
                borderRight: "1px solid white",
                textShadow: "0px 0px",
                transition: "all ease 0.2s",
              };
              this.setState({ columnseleted: !selectedProgram });
            }
          } else {
            if (column.field === selectedProgram) {
              {
                column.headerStyle.backgroundColor = "#2f75b5";
                column.headerStyle.boxShadow = "none";
                column.cellStyle.boxShadow = "none";
                column.headerStyle.borderLeft = "1px solid white";
                column.headerStyle.borderRight = "1px solid white";
                column.cellStyle.borderLeft = "1px solid white";
                column.cellStyle.borderRight = "1px solid white";
                column.headerStyle.borderTop = "1px solid white";
                column.headerStyle.fontSize = "1.5rem";

                column.headerStyle.borderRadius = "10px";
                column.cellStyle.textShadow = "none";
                column.headerStyle.textShadow = "none";
                column.cellStyle.transition = "all ease 0.2s";
                column.headerStyle.transition = "all ease 0.2s";
              }
            } else {
              column.headerStyle.backgroundColor = "#2f75b5";
              column.headerStyle.boxShadow = "none";
              column.cellStyle.boxShadow = "none";
              column.headerStyle.borderLeft = "1px solid white";
              column.headerStyle.borderRight = "1px solid white";
              column.cellStyle.borderLeft = "1px solid white";
              column.cellStyle.borderRight = "1px solid white";
              column.headerStyle.borderTop = "1px solid white";
              column.headerStyle.fontSize = "1.5rem";

              column.headerStyle.borderRadius = "10px";
              column.cellStyle.textShadow = "none";
              column.headerStyle.textShadow = "none";
              column.cellStyle.transition = "all ease 0.2s";
              column.headerStyle.transition = "all ease 0.2s";
            }
          }
        }
      }

      this.setState({ columns });
    } catch (error) {
      console.log(error);
    }
  };

  handleCarrierClick = (header, value, state) => {
    const { programStateType } = this.props;
    let { rowDataList } = this.state;
    let selectedCarrier;
    let isAllSelected = true;
    let response = {};

    for (let row of rowDataList) {
      if (((state && row.name === state) || !state) && row[header]) {
        row.checked[header] = value;
      }
    }

    for (let row of rowDataList) {
      if (row.name !== "TOTAL" && row.checked[header] === false) {
        isAllSelected = false;
        break;
      }
    }

    for (let row of rowDataList) {
      let name = row.name.toLowerCase();
      if (row.name !== "TOTAL")
        for (let program in row.checked) {
          if (row[program].premium !== "Included in Master") {
            if (program !== "name" && row.checked[program]) {
              if (!response[program]) {
                response[program] = [];
              }
              if (name === "master") {
                let l1 =
                  programStateType[program][name]?.without_fundrate || [];
                let l2 = programStateType[program][name]?.with_fundrate || [];
                response[program].push(...l1, ...l2);
              } else {
                response[program].push(name);
              }
            }
          }
        }
    }

    let value_to_send = false;
    if (state) {
      if (isAllSelected) value_to_send = true;
      else value_to_send = false;
    }
    for (let program in programMapping) {
      if (header === program) {
        selectedCarrier = programMapping[program].carrier;
        break;
      }
    }
    this.props.updateSelectedCarrierMap(
      selectedCarrier,
      state ? value_to_send : value,
      header,
      state?.toLowerCase(),
      value,
      response[header]
    );

    this.setState({ rowDataList });
  };

  updateCarrierStatusAndComments = (carrierStatusAndComments) => {
    this.setState({ carrierStatusAndComments });
  };

  prepareColumns = (headers, cacheStateRange, currentCarrierMap) => {
    let { carrierStatusAndComments, selectedCarrier } = this.state;
    let { stateCarrierData, setStateCarrierData } = this.props;

    let classNameMap = {
      MASTER: "brown-text",
      MCP: "dark-blue-text",
      MONO: "red-text",
    };

    let columns = [
      {
        title: (
          <div id="columnselect" className="d-flex justify-content-around">
            {/* <div>Type</div> */}
            <div>State</div>
            <div>Metric</div>
          </div>
        ),
        field: "first_column",
        align: "center",
        width: "140%",
        fixed: "left",
        headerStyle: {
          backgroundColor: "#2f75b5",
          color: "#FFF",
          fontWeight: "bold",
          fontFamily: "MontserratSemiBold",
          borderLeft: "1px solid white",
          borderRight: "1px solid white",
          borderTop: "none",
          fontSize: "1.1rem",
          borderRadius: "13px",
          boxShadow: "none",
          padding: "16px 0px 16px 0px",
          textShadow: "0px 0px",
          left: 0,
          zIndex: 11,
        },
        cellStyle: {
          borderLeft: "1px solid white",
          borderRight: "1px solid white",
          borderTop: "1px solid white",
          borderBottom: "1px solid white",
          borderRadius: "5px",
          boxShadow: "none",
          fontSize: "1.5rem",
          textShadow: "0px 0px",
          padding: "16px 0px 16px 0px",
          left: 0,
          zIndex: 11,
          position: "sticky",
          backgroundColor: "inherit",
          // display: "flex",
          // justifyContent: "center"
        },
        render: (rowData) => (
          <div
            className={"d-flex justify-content-around row "}
            style={{ width: "100%" }}
          >
            {/* {rowData.name !== "TOTAL" && (
              <div
                className={"col-3 small-text-quote-table " + (rowData.name !== "TOTAL" ? classNameMap[rowData.type?.toUpperCase()] : "")}
                style={{ textAlign: "center", alignSelf: "center" }}
              >
                {rowData.type?.toUpperCase()}
              </div>
            )} */}
            <div
              className={
                "col-5  small-text-quote-table " +
                (rowData.name !== "TOTAL"
                  ? classNameMap[rowData.type?.toUpperCase()]
                  : "")
              }
              style={
                rowData.name === "TOTAL"
                  ? { textAlign: "left", maxWidth: "10%", alignSelf: "center" }
                  : { textAlign: "center", alignSelf: "center" }
              }
            >
              {rowData.name}
            </div>
            <div
              className="d-flex justify-content-between col-6 row"
              style={{ textAlign: "left" }}
            >
              {rowData.name !== "TOTAL" && (
                <div
                  className={"col-12 smaller-text-quote-table dark-blue"}
                  style={{
                    marginTop: "5px",
                    padding: "2%",
                    marginBottom: "10px",
                  }}
                >
                  State Type
                </div>
              )}
              <div
                className={
                  "col-12 smaller-text-quote-table " +
                  (rowData.type?.toUpperCase() === "MONO"
                    ? classNameMap[rowData.type?.toUpperCase()]
                    : "green-text")
                }
                style={{
                  marginTop: "5px",
                  padding: "2%",
                  marginBottom: "10px",
                }}
              >
                Premium
              </div>
              <div
                className="col-12 smaller-text-quote-table blue-text"
                style={{
                  marginTop: "5px",
                  padding: "5%",
                  marginBottom: "10px",
                }}
              >
                Payroll
              </div>
              {rowData.name !== "TOTAL" && (
                <>
                  <div
                    className="col-12 smaller-text-quote-table"
                    style={{
                      marginTop: "5px",
                      padding: "5%",
                      marginBottom: "10px",
                    }}
                  >
                    Scheduled Dr/Cr
                  </div>
                  <div
                    className="col-12 smaller-text-quote-table"
                    style={{
                      marginTop: "5px",
                      padding: "5%",
                      marginBottom: "10px",
                    }}
                  >
                    Status
                  </div>
                  <div
                    className="col-12 smaller-text-quote-table"
                    style={{
                      marginTop: "5px",
                      padding: "5%",
                      marginBottom: "10px",
                    }}
                  >
                    Comments
                  </div>
                </>
              )}
            </div>
          </div>
        ),
      },
    ];
    let currProspect = sessionStorage.getItem("currProspect");
    try {
      currProspect = JSON.parse(currProspect);
    } catch (error) {
      currProspect = {};
    }

    let demoPeo = currProspect?.peoDetails?.selectedPeo === "demo";

    let returnShortenCarrieName = (name) => {
      let nameMap = {
        "Zurich American": "Zurich Am.",
        "American Zurich": "Am. Zurich",
        "Berkley Casualty": "Berkley",
        "Carolina Casualty": "Carolina",
        "Midwest Employers Casualty": "Midwest",
      };
      if (name in nameMap) return nameMap[name];
      return name;
    };
    const customCarrierData = [];
    for (let i of Object.keys(stateCarrierData)) {
      if (i.includes("custom_carrier_")) customCarrierData.push(i);
    }
    try {
      for (let header of headers) {
        try {
          let carrier = programMapping[header].carrier;
          allCarriersData.push(
            returnShortenCarrieName(
              programMapping[header]?.actualCarrierName
            )?.toLowerCase()
          );
          allCarriersData.push(
            programMapping[header]?.actualCarrierName?.toLowerCase()
          );
          columns.push({
            title: (
              <div className="d-flex justify-content-center">
                <div
                  id="columnselect"
                  className="w-75"
                  onClick={() => this.updateColumnSelection(header, headers)}
                >
                  {demoPeo
                    ? programMapping[header].carrier?.toUpperCase()
                    : returnShortenCarrieName(
                        programMapping[header].actualCarrierName
                      )}
                </div>
                <input
                  name="radio-input-uw-table"
                  type="radio"
                  value={carrier}
                  onClick={(e) => {
                    let val = e.target.value;
                    this.props.updatePopUpData({
                      show: true,
                      title: "",
                      children: (
                        <StatusAndComments
                          updateCarrierStatusAndComments={
                            this.updateCarrierStatusAndComments
                          }
                          carrierStatusAndComments={carrierStatusAndComments}
                          carrier={val}
                        />
                      ),
                      acceptBtn: "Update",
                      rejectBtn: "Cancel",
                      acceptBtnCallback: async () => {
                        let { carrierStatusAndComments } = this.state;
                        for (let state in stateCarrierData[carrier]) {
                          if (carrierStatusAndComments?.[carrier]?.["status"])
                            stateCarrierData[carrier][state].status =
                              carrierStatusAndComments?.[carrier]?.["status"];
                          if (carrierStatusAndComments?.[carrier]?.["comments"])
                            stateCarrierData[carrier][state].comments =
                              carrierStatusAndComments?.[carrier]?.["comments"];
                        }
                        setStateCarrierData(stateCarrierData);

                        let uid = sessionStorage.getItem("user_id");
                        let uuid_list = JSON.parse(
                          sessionStorage.getItem("sortKeyList")
                        );

                        let isLoggedIn = await Auth.currentAuthenticatedUser();
                        let loggedIn_emailID = isLoggedIn.attributes.email;

                        axios.post(awsUrl + "/api/updateStatusDetails", {
                          button_name: "underwriting_page_status",
                          user_id: loggedIn_emailID,
                          user_email_id: uid,
                          sort_key_list: uuid_list,
                          carrier: carrier,
                          status:
                            carrierStatusAndComments?.[carrier]?.["status"],
                        });

                        this.setState({ carrierStatusAndComments: {} });
                      },
                      rejectBtnCallback: () => {
                        this.setState({ carrierStatusAndComments: {} });
                      },
                    });
                  }}
                />
              </div>
            ),
            field: header,
            width: "140%",
            align: "center",
            headerStyle: {
              backgroundColor: "#2f75b5",
              color: "#FFF",
              fontWeight: "bold",
              fontFamily: "MontserratSemiBold",
              borderLeft: "1px solid white",
              borderRight: "1px solid white",
              borderTop: "none",
              fontSize: "1.1rem",
              borderRadius: "13px",
              boxShadow: "none",
              padding: "16px 0px 16px 0px",
              textShadow: "0px 0px",
              width: "210px !important",
            },
            cellStyle: {
              borderLeft: "1px solid white",
              borderRight: "1px solid white",
              borderTop: "1px solid white",
              borderBottom: "1px solid white",
              borderRadius: "5px",
              boxShadow: "none",
              fontSize: "1.5rem",
              textShadow: "0px 0px",
              padding: "16px 0px 16px 0px",
              // display: "flex",
              // justifyContent: "center"
            },

            //state is rowData.name hence key for stateCarrierData is ${carrier}_${state}
            render: (rowData) => {
              let key = `${carrier.toLowerCase()}_${rowData.name.toLowerCase()}`;

              let comment =
                stateCarrierData?.[carrier.toLowerCase()]?.[
                  rowData.name.toLowerCase()
                ]?.comments || "";

              return rowData?.[header]?.premium ||
                rowData?.[header]?.premium === 0 ? (
                rowData[header].premium !== "Included in Master" ? (
                  <div className="d-flex">
                    <div className="d-flex w-100 flex-column">
                      <span
                        className="small-text-quote-table dark-blue"
                        style={{ position: "relative", top: "-15px" }}
                      >
                        {rowData.type?.toUpperCase() === "MONO"
                          ? "MONO"
                          : this.state.stateTypeList[
                              `${programMapping[header].actualCarrierName}_${rowData.name}`
                            ]?.toUpperCase()}
                      </span>
                      <span
                        className={
                          "small-text-quote-table " +
                          (rowData.type?.toUpperCase() === "MONO"
                            ? classNameMap[rowData.type?.toUpperCase()]
                            : "green-text")
                        }
                        style={{ marginBottom: "10px" }}
                      >
                        {rowData[header].premium === 0
                          ? "N/A"
                          : numeral(rowData[header].premium).format("$0,0")}
                      </span>
                      <span
                        className="small-text-quote-table blue-text"
                        style={{ marginBottom: "10px" }}
                      >
                        {this.formatPayroll(rowData[header].payroll)}
                      </span>
                      {rowData.name !== "TOTAL" && (
                        <div
                          style={{
                            position: "relative",
                            top: "12px",
                          }}
                        >
                          <span
                            className="small-text-quote-table"
                            style={{ marginBottom: "10px" }}
                          >
                            <ScheduledDebitCredit
                              onChangeDebitCreditHandler={
                                this.onChangeDebitCreditHandler
                              }
                              state={rowData.name.toLowerCase()}
                              carrier={carrier.toLowerCase()}
                              creditChecked={
                                stateCarrierData?.[carrier.toLowerCase()]?.[
                                  rowData.name.toLowerCase()
                                ]?.debit_credit?.["isChecked"]
                              }
                              value={
                                stateCarrierData?.[carrier.toLowerCase()]?.[
                                  rowData.name.toLowerCase()
                                ]?.debit_credit?.["value"]
                              }
                              cacheStateRange={cacheStateRange}
                              disableScheculed={
                                rowData[header].premium === 0 ? true : false
                              }
                            />
                          </span>
                          <span
                            style={{
                              width: "125%",
                              marginBottom: "10px",
                            }}
                          >
                            <select
                              className="uw-options"
                              style={{ textAlign: "center" }}
                              value={
                                stateCarrierData?.[carrier.toLowerCase()]?.[
                                  rowData.name.toLowerCase()
                                ]?.status
                              }
                              onChange={(e) => {
                                this.onStatusSelectHandler(
                                  e.target.value,
                                  rowData.name.toLowerCase(),
                                  carrier.toLowerCase()
                                );
                              }}
                            >
                              <option value="select">Select</option>
                              <option value="bound">Bound</option>
                              <option value="quoted">Quoted</option>
                              <option value="closed">Closed</option>
                              <option value="declined">Declined</option>
                              <option value="uw_questions">UW Questions</option>
                              <option value="submitted">Submitted</option>
                            </select>
                          </span>
                          <span
                            style={{
                              marginBottom: "10px",
                            }}
                          >
                            <Comments
                              value={comment}
                              callbackFunc={(c) => {
                                this.onChangeCommentHandler(
                                  c,
                                  rowData.name.toLowerCase(),
                                  carrier.toLowerCase()
                                );
                              }}
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="small-text-quote-table">
                    {rowData[header].premium}
                  </div>
                )
              ) : (
                <div></div>
              );
            },
          });
        } catch (e) {
          console.log("error here in program mapping", header, e);
        }
      }
    } catch (error) {
      console.log(error);
    }
    try {
      for (let carrier of customCarrierData) {
        allCarriersData.push(carrier.split("custom_carrier_")[1].toLowerCase());
        try {
          columns.push({
            title: (
              <div className="d-flex justify-content-center">
                <div id="columnselect" className="w-75">
                  {carrier.split("custom_carrier_")[1]}
                </div>
                <input
                  name="radio-input-uw-table"
                  type="radio"
                  value={carrier}
                  onClick={(e) => {
                    let val = e.target.value;
                    this.props.updatePopUpData({
                      show: true,
                      title: "",
                      children: (
                        <StatusAndComments
                          updateCarrierStatusAndComments={
                            this.updateCarrierStatusAndComments
                          }
                          carrierStatusAndComments={carrierStatusAndComments}
                          carrier={val}
                        />
                      ),
                      acceptBtn: "Update",
                      rejectBtn: "Cancel",
                      acceptBtnCallback: () => {
                        let { carrierStatusAndComments } = this.state;
                        for (let state in stateCarrierData[carrier]) {
                          if (carrierStatusAndComments?.[carrier]?.["status"])
                            stateCarrierData[carrier][state].status =
                              carrierStatusAndComments[carrier]["status"];
                          if (carrierStatusAndComments?.[carrier]?.["comments"])
                            stateCarrierData[carrier][state].comments =
                              carrierStatusAndComments[carrier]["comments"];
                        }
                        setStateCarrierData(stateCarrierData);
                        this.setState({ carrierStatusAndComments: {} });
                      },
                      rejectBtnCallback: () => {
                        this.setState({ carrierStatusAndComments: {} });
                      },
                    });
                  }}
                />
              </div>
            ),
            field: carrier.split("custom_carrier_")[1],
            width: "140%",
            align: "center",
            headerStyle: {
              backgroundColor: "#2f75b5",
              color: "#FFF",
              fontWeight: "bold",
              fontFamily: "MontserratSemiBold",
              borderLeft: "1px solid white",
              borderRight: "1px solid white",
              borderTop: "none",
              fontSize: "1.1rem",
              borderRadius: "13px",
              boxShadow: "none",
              padding: "16px 0px 16px 0px",
              textShadow: "0px 0px",
              width: "210px !important",
            },
            cellStyle: {
              borderLeft: "1px solid white",
              borderRight: "1px solid white",
              borderTop: "1px solid white",
              borderBottom: "1px solid white",
              borderRadius: "5px",
              boxShadow: "none",
              fontSize: "1.5rem",
              textShadow: "0px 0px",
              padding: "16px 0px 16px 0px",
              // display: "flex",
              // justifyContent: "center"
            },
            render: (rowData) => {
              const getTotalPremium = () => {
                let totalPremium = 0;
                for (let i in stateCarrierData[carrier]) {
                  totalPremium += numeral(
                    stateCarrierData[carrier]?.[i]?.premium
                  ).value();
                }
                return numeral(totalPremium).format("$0,0");
              };
              const stateType =
                stateCarrierData?.[carrier]?.[rowData.name.toLowerCase()]
                  ?.stateType;
              return (
                <div className="d-flex">
                  <div className="d-flex w-100 flex-column">
                    <span
                      className="small-text-quote-table dark-blue"
                      style={{
                        marginBottom: "10px",
                      }}
                    >
                      {stateType ? stateType : rowData.type?.toUpperCase()}
                    </span>
                    {rowData.name !== "TOTAL" ? (
                      <span
                        className={
                          "small-text-quote-table " +
                          (rowData.type?.toUpperCase() === "MONO"
                            ? "red-text"
                            : "green-text")
                        }
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        {stateCarrierData[carrier]?.[rowData.name.toLowerCase()]
                          ?.premium || "N/A"}
                      </span>
                    ) : (
                      <span
                        className={
                          "small-text-quote-table " +
                          (rowData.type?.toUpperCase() === "MONO"
                            ? "red-text"
                            : "green-text")
                        }
                        style={{
                          marginBottom: "10px",
                        }}
                      >
                        {getTotalPremium()}
                      </span>
                    )}
                    <span
                      className="small-text-quote-table blue-text"
                      style={
                        rowData.name === "TOTAL"
                          ? {
                              marginBottom: "10px",
                            }
                          : {
                              marginBottom: "10px",
                            }
                      }
                    >
                      {this.formatPayroll(
                        this.state.payrollData[rowData?.name]
                      )}
                    </span>
                    {rowData.name !== "TOTAL" && (
                      <div
                        style={{
                          position: "relative",
                          top: "7px",
                        }}
                      >
                        <span
                          className="small-text-quote-table"
                          style={{ marginBottom: "10px" }}
                        >
                          <ScheduledDebitCredit />
                        </span>
                        <span
                          style={{
                            width: "125%",
                            marginBottom: "10px",
                          }}
                        >
                          <select
                            className="uw-options"
                            style={{
                              textAlign: "center",
                            }}
                            value={
                              stateCarrierData[carrier]?.[
                                rowData.name.toLowerCase()
                              ]?.status
                            }
                            onChange={(e) => {
                              this.onStatusSelectHandler(
                                e.target.value,
                                rowData.name.toLowerCase(),
                                carrier
                              );
                            }}
                          >
                            <option value="select">Select</option>
                            <option value="bound">Bound</option>
                            <option value="quoted">Quoted</option>
                            <option value="closed">Closed</option>
                            <option value="declined">Declined</option>
                            <option value="uw_questions">UW Questions</option>
                            <option value="submitted">Submitted</option>
                          </select>
                        </span>
                        <span
                          style={{
                            marginBottom: "20px",
                          }}
                        >
                          <Comments
                            value={
                              stateCarrierData[carrier]?.[
                                rowData.name.toLowerCase()
                              ]?.comments
                            }
                            callbackFunc={(c) => {
                              this.onChangeCommentHandler(
                                c,
                                rowData.name.toLowerCase(),
                                carrier
                              );
                            }}
                          />
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            },
          });
        } catch (err) {
          console.log(err);
        }
      }
    } catch (error) {
      console.log(error);
    }

    return columns;
  };

  formatPayroll = (num) => {
    if (num) {
      num = Number(num);
      return numeral(num).format(num < 1000 ? "$0,0" : "$0,0ak");
    }
    return num;
  };

  prepareCacheStateRange = async (quoteTableData) => {
    let cacheStateRange = {},
      promiseList = [];

    for (let state in quoteTableData) {
      if (state !== "total") {
        promiseList.push(
          new Promise((resolve, reject) => {
            axios
              .get(awsUrl + "/api/getScheduleDebitCreditRange/" + state)
              .then(async (response) => {
                let range = response.data;
                cacheStateRange[state] = { credit: range[1], debit: range[0] };
                resolve();
              })
              .catch((err) => {
                cacheStateRange[state] = { credit: 0, debit: 0 };
                resolve();
              });
          })
        );
      }
    }

    await Promise.all(promiseList);
    return cacheStateRange;
  };

  prepareTableData = async (quoteTableData, programs, currentCarrierMap) => {
    let cacheStateRange = await this.prepareCacheStateRange(quoteTableData);
    let rowDataList = this.prepareRowDataList(quoteTableData, programs);
    let columns = this.prepareColumns(
      programs,
      cacheStateRange,
      currentCarrierMap
    );

    this.setState({
      rowDataList,
      columns,
      isLoading: false,
    });
  };

  async componentDidUpdate(prevProps, prevState) {
    if (
      !isDeepEquals(
        prevProps.perStateQuoteTableData,
        this.props.perStateQuoteTableData
      ) ||
      !isDeepEquals(prevProps.currentCarrierMap, this.props.currentCarrierMap)
    ) {
      await this.prepareTableData(
        this.props.perStateQuoteTableData,
        this.props.programs,
        this.props.currentCarrierMap
      );
    }
    if (prevProps.carrierBasedData !== this.props.carrierBasedData) {
      this.prepareStateTypeList();
      console.log("re-rendering");
    }
  }

  getCarrierFromProgram = (program) => {
    for (let prgm in programMapping) {
      if (prgm === program) return programMapping[prgm].carrier;
    }
    return "";
  };

  prepareStateTypeList = () => {
    const { carrierBasedData } = this.props;
    const data = carrierBasedData;
    const stateTypeListCopy = {};
    for (let i of data.uuid) {
      let [peo, key] = i.split("+");
      peo = peo.split("_")[1];
      for (let j in data.data[key][peo].carrier_location_data) {
        const program = data.data[key][peo].carrier_location_data[
          j
        ].program.split("-")[1];
        const state = data.data[key][peo].carrier_location_data[
          j
        ].state.toUpperCase();
        stateTypeListCopy[`${program}_${state}`] =
          data.data[key][peo].carrier_location_data[j].masterOrMcp;
      }
      this.setState({ stateTypeList: stateTypeListCopy });
    }
  };
  componentDidMount = async () => {
    let { amtrustBlindButton } = this.state;
    const currProspect = sessionStorage.getItem("currProspect");
    const payrollDataCopy = {};
    const childrenLoc = JSON.parse(currProspect)?.childrenLoc;
    for (let i in childrenLoc) {
      for (let j in childrenLoc[i].classCodesInfo) {
        const payrollValue = childrenLoc[i].classCodesInfo[j].payroll.value;
        const cleanedPayroll = parseFloat(payrollValue.replace(/[$,]/g, ""));

        if (payrollDataCopy[childrenLoc[i].state.value]) {
          payrollDataCopy[childrenLoc[i].state.value] += cleanedPayroll;
        } else {
          payrollDataCopy[childrenLoc[i].state.value] = cleanedPayroll;
        }
      }
    }
    let total = 0;
    for (let i in payrollDataCopy) {
      total += payrollDataCopy[i];
    }
    payrollDataCopy["TOTAL"] = total;
    this.setState({ payrollData: payrollDataCopy });
    let amtrustEligibility = sessionStorage.getItem("AmtrustEligibility");
    let eligibilityStatus = JSON.parse(amtrustEligibility)?.Eligibility;
    if (amtrustEligibility) {
      if (eligibilityStatus === "BindEligible") {
        this.setState({ amtrustBlindButton: false });
      } else {
        this.setState({ amtrustBlindButton: true });
      }
    }
  };
  updateColumns = (newColumns) => {
    this.setState({ columns: newColumns });
  };

  amtrustQuoteBind = async () => {
    let response = true;
    try {
      let amtrustAccountDetails = sessionStorage.getItem(
        "amtrustAccountDetails"
      );
      let amtrust_eligibility = sessionStorage.getItem("AmtrustEligibility");
      amtrustAccountDetails = JSON.parse(amtrustAccountDetails);
      amtrust_eligibility = JSON.parse(amtrust_eligibility);
      let user_id = sessionStorage.getItem("user_id");
      let payoEligibility = amtrust_eligibility;
      let currProspect = sessionStorage.getItem("currProspect");

      let amtrustTriggerResponse = await axios.post(
        awsUrl2 + `/api/triggerAmtrustBindQuote`,
        JSON.stringify({
          quoteId: amtrustAccountDetails.QuoteId,
          amtrustPaygoEligibility: payoEligibility,
          user_email_id: user_id
            ? user_id
            : currProspect.companyProfile.companyName &&
              currProspect.companyProfile.companyName.value
            ? currProspect.companyProfile.companyName.value.toLowerCase()
            : "",
          sortKeyList: sessionStorage.getItem("sortKeyList")
            ? JSON.parse(sessionStorage.getItem("sortKeyList"))
            : undefined,
        })
      );
      // await axios.post(awsUrl2 + `/api/triggerAmtrustBindQuote`);
      let uuid;
      if (amtrustTriggerResponse.data.uuid) {
        uuid = amtrustTriggerResponse.data.uuid;
      } else {
        throw "error in amtrust create quote trigger";
      }

      let amtrustQuoteBindResponse;
      do {
        amtrustQuoteBindResponse = await amtrustResponse(
          uuid,
          "getBindQuoteResponse"
        );

        if (amtrustQuoteBindResponse === "error") {
          throw "error in amtrust quote binding";
        } else if (
          amtrustQuoteBindResponse &&
          amtrustQuoteBindResponse !== "processing"
        ) {
          toast.success("Quote Binded", {
            duration: 3000,
          });
          amtrustAccountDetails = {
            ...amtrustAccountDetails,
            BindData: amtrustQuoteBindResponse,
          };
        }
      } while (amtrustQuoteBindResponse === "processing");
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(amtrustAccountDetails)
      );
    } catch (error) {
      console.log(error);
      response = false;
      toast.error("Error while binding the Quote", {
        duration: 3000,
      });
      this.setState({ amtrustBindError: true });
    }
    return response;
  };

  handleClose() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  updateShowState(newState) {
    this.setState({ show: newState });
  }

  render() {
    const {
      rowDataList,
      columns: columnsState,
      isLoading,
      displayQuotesLoader,
      displayRatesLoader,
      download_data,
      master_data,
      carrier_data,
      processingInProgress,
      amtrustBlindButton,
      amtrustBindError,
    } = this.state;
    const {
      masterWithoutFundRateMap,
      programs,
      peoData,
      programStateType,
      uw_net_rates,
      updatePopUpData,
      contextFileList,
      setFileList,
    } = this.props;

    let incInMaster = false;

    for (let row of rowDataList) {
      for (let program of programs) {
        if (row?.[program]?.premium === "Included in Master") {
          incInMaster = true;
          break;
        }
      }
    }

    let currProspect = sessionStorage.getItem("currProspect");
    try {
      currProspect = JSON.parse(currProspect);
    } catch (error) {
      currProspect = {};
    }

    let carrierList_label = currProspect?.carrierList?.value.map(
      (val) => val.label
    );
    let isAmtrustCheck;
    if (carrierList_label) {
      if (carrierList_label.includes("Amtrust")) {
        isAmtrustCheck = true;
      } else {
        isAmtrustCheck = false;
      }
    } else {
      isAmtrustCheck = false;
    }

    let demoPeo = currProspect?.peoDetails?.selectedPeo === "demo";

    const columns = columnsState.map((column) => {
      return { ...column };
    });

    for (let program in masterWithoutFundRateMap) {
      let key;
      if (demoPeo) {
        key = `Demo-${programMapping[program].carrier}`;
      } else {
        key = program;
      }
    }

    let childrenLoc = currProspect?.childrenLoc;
    let excludedStateCarrMap = {};

    if (childrenLoc)
      for (let key in childrenLoc) {
        if (programs)
          for (let program of programs) {
            if (
              peoData[program] &&
              !(
                childrenLoc[key]?.state?.value?.toLowerCase() in
                peoData[program]
              )
            ) {
              if (!(program in excludedStateCarrMap))
                excludedStateCarrMap[program] = [];
              if (
                !excludedStateCarrMap[program].includes(
                  childrenLoc[key]?.state?.value?.toLowerCase()
                )
              )
                excludedStateCarrMap[program].push(
                  childrenLoc[key]?.state?.value?.toLowerCase()
                );
            }
          }
      }

    let prg_len = "";

    if (columns.length === 2) prg_len = "quote_table_1header";
    else if (columns.length === 3) prg_len = "quote_table_2header";
    else if (columns.length === 4) prg_len = "quote_table_3header";
    else if (columns.length === 5) prg_len = "quote_table_4header";

    return (
      <>
        <Toaster />
        <LoadingOverlay active={processingInProgress} spinner>
          <div id="underwriting-page" className="container-fluid per98">
            <NewProgress />
            <div id="underwriting-table">
              <div>
                <div
                  className="pink-header font-family-montserrat-bold"
                  style={{ fontSize: "x-large" }}
                >
                  Here's Your UnderWriting Price Indication
                </div>
                {amtrustBindError && (
                  <div className="amtrust-error">
                    <MdOutlineReportGmailerrorred size={25} />
                    <b>
                      Error while binding the quote for Amtrust. Please Try
                      Agian Later.
                    </b>
                  </div>
                )}
                <div className={"container-fluid quotes-table " + prg_len}>
                  {isLoading && (
                    <div className="d-flex justify-content-center">
                      <SmallLoader />
                    </div>
                  )}
                  {rowDataList.length > 0 && (
                    <>
                      <AddCarrier
                        columns={this.state.columns}
                        updateColumns={this.updateColumns}
                        payrollData={this.state.payrollData}
                        formatPayroll={this.formatPayroll}
                        stateCarrierData={this.props.stateCarrierData}
                        setStateCarrierData={this.props.setStateCarrierData}
                        updatePopUpData={this.props.updatePopUpData}
                        allCarriersData={allCarriersData}
                      />
                      <MaterialTable
                        isLoading={isLoading}
                        options={{
                          search: false,
                          paging: false,
                          showTitle: false,
                          sorting: false,
                          fixedColumns: {
                            left: 1,
                            right: 0,
                          },
                        }}
                        columns={columns}
                        data={rowDataList}
                      />
                    </>
                  )}
                </div>
                <div
                  className="create-html-container"
                  style={{ visibility: "hidden", position: "absolute" }}
                >
                  {download_data.length > 0 ? download_data : []}
                </div>
                <div
                  className="create-html-container2"
                  style={{ visibility: "hidden", position: "absolute" }}
                >
                  {master_data?.length ? master_data : []}
                </div>
                <div
                  className="create-html-container2"
                  style={{ visibility: "hidden", position: "absolute" }}
                >
                  {carrier_data?.length ? carrier_data : []}
                </div>
              </div>
            </div>
          </div>
          {isAmtrustCheck ? (
            <div
              className="submit-uw-details mt-3 mb-3"
              style={{ display: "flex", justifyContent: "space-evenly" }}
            >
              <button
                className="btn btn-warning"
                onClick={() => {
                  this.props.updatePopUpData({
                    show: true,
                    title: "Are you sure you want to proceed ?",
                    acceptBtn: "Yes",
                    rejectBtn: "Cancel",
                    acceptBtnCallback: () => {
                      let emptyCarrierNameFlag = false;
                      for (let i of columns) {
                        if (i.field == "") {
                          emptyCarrierNameFlag = true;
                          break;
                        }
                      }
                      !emptyCarrierNameFlag
                        ? this.submitHandler()
                        : this.setState({
                            emptyCarrierErrorMessage:
                              "One or more Carrier Name input boxes is empty. Please fill the Carrier Name to submit.",
                          });
                    },
                    rejectBtnCallback: () => {},
                  });
                }}
              >
                Save
              </button>
              {Object.keys(process.env).includes("REACT_APP_UW_GROUPS") &&
                process.env.REACT_APP_MODE !== "prod" && (
                  <>
                    <button
                      className="btn btn-warning"
                      onClick={(e) => {
                        this.handleShow();
                      }}
                    >
                      Submit to Amtrust
                    </button>
                    <Modal show={this.state.show} onHide={this.handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          <b>Amtrust UW Questions</b>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <AmtrustUnderQues
                          updateShowState={this.updateShowState}
                        />
                      </Modal.Body>
                    </Modal>
                    <button
                      className={`btn ${
                        amtrustBlindButton ? "btn-warning" : "btn-success"
                      }`}
                      onClick={async () => await this.amtrustQuoteBind()}
                      disabled={amtrustBlindButton}
                    >
                      Proceed to bind online
                    </button>
                  </>
                )}
              <p
                style={{
                  color: "red",
                  padding: "10px",
                }}
              >
                {this.state.emptyCarrierErrorMessage}
              </p>
            </div>
          ) : (
            <div className="submit-uw-details mt-3 mb-3">
              <button
                className="btn btn-warning"
                onClick={() => {
                  let emptyCarrierNameFlag = false;
                  for (let i of columns) {
                    if (i.field == "") {
                      emptyCarrierNameFlag = true;

                      break;
                    }
                  }
                  !emptyCarrierNameFlag
                    ? this.submitHandler()
                    : this.setState({
                        emptyCarrierErrorMessage:
                          "One or more Carrier Name input boxes is empty. Please fill the Carrier Name to submit.",
                      });
                }}
              >
                Save
              </button>
              <p
                style={{
                  color: "red",
                  padding: "10px",
                }}
              >
                {this.state.emptyCarrierErrorMessage}
              </p>
            </div>
          )}

          {rowDataList.length > 0 && (
            <UpdateRates
              programStateType={programStateType}
              peoData={peoData}
              programs={programs}
              uw_net_rates={uw_net_rates}
              updateData={updateData}
              updatePopUpData={updatePopUpData}
              fileList={contextFileList}
              carrierBasedData={this.props.carrierBasedData.data}
              stateCarrierData={this.props.stateCarrierData}
              rowDataList={rowDataList}
            />
          )}
          <ErrorPopUp dataToShow={this.createPopData()} />
        </LoadingOverlay>
      </>
    );
  }
}

export default function UnderwritingTableWrapper(props) {
  return (
    <LibertateContext.Consumer>
      {(context) => (
        <UnderwritingTable
          updatePopUpData={props.updatePopUpData}
          perStateQuoteTableData={context.perStateQuoteTableData}
          programs={context.programs}
          stateCarrierData={context.stateCarrierData}
          masterWithoutFundRateMap={context.masterWithoutFundRateMap}
          currentCarrierMap={context.currentCarrierMap}
          excludedClassCodeMap={context.excludedClassCodeMap}
          peoData={context.peoData}
          programStateType={context.programStateType}
          setPerStateQuoteTableData={context.setPerStateQuoteTable}
          setStateCarrierData={context.setStateCarrierData}
          updateSelectedCarrierMap={context.updateSelectedCarrierMap}
          uw_net_rates={context.uwNetRates}
          contextFileList={context.uwFileList}
          carrierBasedData={context.carrierBasedData}
        />
      )}
    </LibertateContext.Consumer>
  );
}
